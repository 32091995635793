import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { ImpactingKnowledgeType, NetworkInfo, NetworkType } from "./entities";
import * as dayjs from "dayjs";
import { Utilities } from "./utils";

@Injectable({
  providedIn: "root"
})
export class CustomValidators {
  constructor() {}

  atLeastOneElements() {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value.length >= 1) {
        return null;
      }

      return { atLeastOneElements: { valid: false } };
    };
  }
}

export function bookingCoPsValidator(
  isPrivate: boolean, promotingNetworks: AbstractControl, privateNetworks: AbstractControl
)  {
  // uses the parameters above when returning the actual validator function
  return function(control: AbstractControl) {
    if (control !== promotingNetworks) {
      promotingNetworks.updateValueAndValidity();
      return null;
    }

    let valid = false;
    if (promotingNetworks) {
      valid = ( promotingNetworks.value.find((n: NetworkInfo) => n.type === NetworkType.CoP) );
    }

    if (!valid && isPrivate && privateNetworks) { 
      valid = ( privateNetworks.value.length > 0 );
    }

    if (
      (!promotingNetworks && !privateNetworks) ||
      (promotingNetworks && promotingNetworks.value.length > 0) ||
      (privateNetworks && privateNetworks.value.length > 0)
    ) {
      promotingNetworks.markAsDirty();
      privateNetworks.markAsDirty();
    }

    return !valid ? {"required": {value: "null", filedId: "promotingNetworks"}} : null;
  };
}

export function bookingCoPsAndExtValidator(control: AbstractControl)  {
  let valid = true;

  if (control.value.length > 0) {
    control.markAsDirty();
  }

  return !valid ? {"required": {value: "null", filedId: "promotingNetworks"}} : null;
}

export function bookingPrivateValidator(control: AbstractControl)  {
  let valid = true;

  if (control.value.length <= 0) {
    valid = false;
    
  }
  
  if (control.value.length > 0) {
    control.markAsDirty();
  }

  return !valid ? {"required": {value: "null", filedId: "privateNetworks"}} : null;
}
export function nullValidator(control: AbstractControl)  {
  let valid = false;
  if (control.value) {
    valid = control.value.every(v => !!v);
  }

  return !valid ? {"nullvalidator": {value: "null", filedId: "originalEvaluationChoices"}} : null;
}

export function emailValidator(control: AbstractControl) {
  let valid = true;
  if (control.value) {
    const res = /<p>(.*)<\/p>/.exec(control.value);
    const foo = res ? /<p>(.*)<\/p>/.exec(control.value)[1] : control.value;
    valid = foo.split(";").every(v =>
      !v.trim() || v.trim().length === 0 || /[a-zA-Z0-9.,-_']+@[a-zA-Z0-9.-]+\.[a-zA-Z]+/gi.test(v.trim()));
    }

  return !valid ? {"emailValidator": {value: "valid", filedId: "otherRecipients"}} : null;
}

export function minWordsValidator(minWords: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || typeof control.value !== 'string') {
      return null;
    }
    const plainText = control.value.replace(/<[^>]+>/g, ' ').trim();
    const wordCount = plainText.split(/\s+/).length;
    return wordCount >= minWords ? null : { minWords: { required: minWords, actual: wordCount } };
  };
}

export function dateValidator(refDate: dayjs.Dayjs): ValidatorFn {
  return (c: AbstractControl): { [key: string]: any } => {
    const date = Utilities.adjustDateLocalToMilan(c.value);
    const milanRefDate = dayjs(
      Utilities.adjustDateLocalToMilan(
      refDate
      .toDate()))
      .hour(0)
      .minute(0)
      .second(0);

    if (!c.dirty || !c.value || dayjs(date).isAfter(milanRefDate)) {
      return null;
    }

    return { pastDate: { valid: false } };
  };
}
